import React from "react";

function HeaderService() {
  return (
    <div className="header--service" id="service">
      <header>
        <div className="header--service--text">
          <div id="demos" className="carousel slide" data-ride="carousel">
            <ul className="carousel-indicators">
              <li
                data-target="#demos" 
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#demos" data-slide-to="1"></li>
            </ul>

            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="d-block img-fluid" src="./images/sv-01.jpg"/>
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-21.jpg" />
              </div>
            </div>

            <a
              className="carousel-control-prev"
              href="#demos"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon text-bg-warning"></span>
            </a>
            <a
              className="carousel-control-next"
              href="#demos"
              data-slide="next"
            >
              <span className="carousel-control-next-icon text-bg-warning"></span>
            </a>
          </div>

        {/* <img src="./images/sv-01.jpg" className="header--image" /> */}
        </div>
      </header>
    </div>
  );
}

export default HeaderService;
