import React from 'react';
// import '../App.css';
import '../../src/css/Index.css';

import HeaderBar from './HeaderBar';
import Introserv from './Introserv';
import Intro from './Intro';
import Footer from './Footer';
import CopyRight from './CopyRight';
import Service from './Service';
import ProAcctax from './ProAcctax';
import HeaderService from './HeaderService';
import HeaderCarousel from './HeaderCarousel';
import Mapaccount from './Mapaccount';

const Home = () => {	
	return (
		<div>
			<HeaderBar/>			
			<HeaderService/>
			<Introserv/>
			<Service/>			
			<Intro/>	
			<HeaderCarousel/>								
			<ProAcctax/>
			<Mapaccount/>
			<Footer/>		
			<CopyRight/>
		</div>
	);
}

export default Home;