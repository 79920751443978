import React from "react";

const CopyRight2 = () => {
  return (
    <div>
      <footer>
        <p className="copyright">
        Copyright&nbsp;
        <i class="bi bi-c-circle icon-white"></i>
        &nbsp;2021&nbsp;All Rights Reserved.
        </p>
      </footer>
    </div>
  );
};

export default CopyRight2;
