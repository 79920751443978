import React from "react";
import { IconContext } from "react-icons";
import { FaCommentDollar } from "react-icons/fa";

import HeaderBar from "./HeaderBar";
import CopyRight2 from "./CopyRight2";

import { Link } from "react-router-dom";

function Intro4() {
  return (
    <div className="container-full" id="top">
      <HeaderBar />
      <div className="main--intro">
        <div className="d-flex flex-row">
          <div className="text-primary d-flex align-items-center">
            <i class="bi bi-currency-exchange icon-intro-lg"></i>
          </div>
          <div className="p-5 text-primary fw-bold intro-item-header">ค่าบริการที่เหมาะสม คุ้มค่า</div>
        </div>

        {/* <div>
          <hr />
          <p className="indent">ค่าบริการ ในราคาที่เหมาะสม คุ้มค่า</p>          
        </div> */}

        <div className="header--step">
          <img src="./images/sv-20.jpg" className="img-fluid" />
        </div>

        <span className="previous-intro">
          <Link to="/">ย้อนกลับ</Link>
        </span>
      </div>

      <CopyRight2 />
    </div>
  );
}

export default Intro4;
