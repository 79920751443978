import React from "react";

import HeaderBar from "./HeaderBar";

import CopyRight2 from "./CopyRight2";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";

function Proposal() {
  return (
    <div className="proposal-full">
      <HeaderBar />
      <div className="proposal">
        <div className="header--step">
          <img src="./images/sv-01.jpg" className="img-fluid" />
        </div>

        <div className="header--step">
          <img src="./images/sv-02.jpg" className="img-fluid" />
        </div>

        <div className="header--step">
          <img src="./images/sv-03.jpg" />
        </div>

        <div className="header--step">
          <img src="./images/sv-04.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-05.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-06.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-07.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-08.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-09.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-10.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-11.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-12.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-13.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-14.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-15.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-16.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-17.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-18.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-19.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-20.jpg" className="img-fluid"/>
        </div>

        <div className="header--step">
          <img src="./images/sv-21.jpg" className="img-fluid"/>
        </div>

        <br />
        <br />
        <div className="previous-intro">
          <p>
            <Link to="/">กลับหน้าหลัก</Link>
          </p>
        </div>

      </div>
      <CopyRight2 />
    </div>
  );
}

export default Proposal;
