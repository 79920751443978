import React, { useState, useEffect } from "react";
import Axios from "axios";

const Users = () => {
  const [userList, setUserList] = useState([]);

  const getUsers = () => {
    Axios.get(process.env.REACT_APP_API_URL + "/controllers/user/getUsers")
      .then((res) => setUserList(res.data))
      .catch((err) => alert(err));
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="table-responsive w-50 m-auto">
      <p>Username</p>
      <table className="table table-striped table-bordered table-hover table-sm">
        <thead className="table-success">
          <tr>
            <th className="col-sm-1">Item</th>
            <th className="col-sm-7">username</th>
            <th className="col-sm-4">password</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((val, key) => {            
            return (
              <tr key={key}>
                <td>{val.id}</td>
                <td>{val.username}</td>
                <td>{val.password}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
