import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
// import localIpUrl from "local-ip-url";
//import { connect } from "react-redux";
//import {countersFetch,countersFetchAll} from '../actions/index'

import { parse } from "ipaddr.js";

import "bootstrap-icons/font/bootstrap-icons.css";

function ProAcctax() {
  const [count, setCount] = useState(0);
  const [ip, setIp] = useState(0);

  //const apiUrl = process.env.NODE_ENV === 'production' ? 'https://example.com' : process.env.REACT_APP_DEV_API_URL;

  const fetchData = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/controller/counter/count")
      // .get('http://localhost:5000/controller/counter/count')
      .then((response) => {
        let iCount = 0;

        if (!isNaN(response.data[0].total)) {
          iCount = Number(response.data[0].total);
        } else {
          iCount = 0;
        }
        setCount(iCount);
      })
      .catch((err) => setCount(0));
  };

  useEffect(() => {
    // fetchData();
    //checkIp();
    //let iCount = props.countersFetchAll.count;
    //alert(iCount);
    // if (iCount > 1) {
    //   iCount = 1;
    // } else {
    //   iCount = 10;
    // }
    //console.log(iCount);
    // setCount(iCount);
    //alert(count);
  }, [count]);

  const addData = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/controller/counter/add")
      .then((resp) => console.log("response: ", resp))
      .catch((err) => console.log(err));
  };

  /*const checkIp = () => {
          axios.get(process.env.REACT_APP_API_URL + '/controller/counter/ip')
          .then((res) => {
              setIp(res.data);				
          })
          .catch(err => console.log(err));
      }*/

  //footer--program

  return (
    <div className="footer--acctax">
      <div className="footer--top"></div>

      <div className="footer--bottom">
        <div>
          <button
            className="btn btn-lg bg-success text-white" data-toggle="tooltip" data-placement="top" title="โปรแกรมบัญชี AccTax"
            onClick={() => setCount(count + 1)}
          >
            <i class="bi bi-arrow-down-square icon-sm"></i>
            <span>
              <Link
                to="/files/AccTax 1.1.rar"
                target="_blank"
                download
                onClick={() => (ip == 0 ? addData() : setCount(count + 1))}
              >
                <span className="text-white w-auto download-text">
                  &nbsp;Download โปรแกรม
                </span>
              </Link>
            </span>
          </button>
        </div>

        <div>
          <button className="btn btn-lg bg-warning text-white" data-toggle="tooltip" data-placement="top" title="คู่มือโปรแกรม AccTax">
            <i class="bi bi-file-pdf icon-sm"></i>
            &nbsp;
            <span>
              <Link to="/files/ACCTAX-MANUAL.pdf" target="_blank" download>
                <span className="text-white w-auto download-text">
                  Download คู่มือโปรแกรม
                </span>
              </Link>
            </span>
          </button>
        </div>

        <div>
          <div>
            <a href="http://line.me/ti/p/~204cczdz">
              <i class="bi bi-line icon-sm icon-white"></i>
              <span className="align-items-center text-white">
                &nbsp;@204cczdz
              </span>
            </a>
          </div>

          <div>
            <a href="https://www.facebook.com/BJ RT/">
              <i class="bi bi-facebook icon-sm icon-white"></i>
            </a>
          </div>

          <div>
            <a href="https://www.youtube.com/@acctax-ac">
              {" "}
              <i class="bi bi-youtube icon-sm icon-white"></i>
              &nbsp;
              <span className="align-items-center text-white">
                &nbsp;@acctax-ac
              </span>
            </a>
          </div>

          <div>
            <a href="https://twitter.com/BJRT54900792">
              <i class="bi bi-twitter icon-sm icon-white"></i>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>

        <div>
        <button className="btn btn-lg bg-info text-white" data-toggle="tooltip" data-placement="top" title="โปรแกรมเก็บไฟล์งาน ทุกประเภท">
            <i class="bi bi-filetype-xlsx icon-sm"></i>
            &nbsp;
            <span>
              <Link to="/files/AccFiles 1.1.rar" target="_blank" download>
                <span className="text-white w-auto download-text">
                  Download AccFiles
                </span>
              </Link>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProAcctax;
