import React from "react";
//import { IconContext } from "react-icons";
//import {FaDiceSix}  from "react-icons/fa";

import HeaderBar from "./HeaderBar";
//import Footer from './Footer';
import CopyRight2 from "./CopyRight2";

import { Link, useLocation } from "react-router-dom";

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faAlignCenter, fas } from '@fortawesome/free-solid-svg-icons';
//import { fab, faCentercode } from '@fortawesome/free-brands-svg-icons';
//import { far } from '@fortawesome/free-regular-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//library.add(fas, fab, far);

function Service6() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  return (
    <div className="container-full" id="top">
      <HeaderBar />
      <div className="service1-6">
        <div className="service--header align-items-center">
          {/* <div>
            {" "}
            <img
              src="../images/acctax.jpg"
              alt=""
              className="acctax-logo zoom"
            />
          </div> */}
          <div className="d-block"><i class="bi bi-stack icon-md text-primary"></i></div>
          <div className="content__header--mian">บริการเสริมพิเศษ</div>
        </div>

        <hr />
        <div>
          <ol type="1" className="fw-normal">
            <li>ฟรีโปรแกรมบัญชี AccTax</li>
            <li>บริการขอ ภ.อ.01 ยื่นแบบชำระภาษีทาง Internet</li>
            <li>จดภาษีมูลค่าเพิ่มทางอินเตอร์เน็ต</li>
            <li>ขอรหัสยื่นงบในระบบ e-filing</li>
            <li>จัดทำรายงานการประชุม สำหรับเปิดบัญชีธนาคาร</li>
            <li>คู่มือข้อควรปฎิบัติเบื้องต้น ภายหลังจากการจดทะเบียนแล้ว</li>
            <li>
              รับสิทธิพิเศษ จะได้รับส่วนลดค่าทำบัญชีฟรี 2 เดือน
              เมื่อรับบริการจดทะเบียนจัดตั้งบริษัท
            </li>
          </ol>
        </div>
        <br />
        {/* <span style={{display: 'block',float:'right', fontSize: '16px', letterSpacing: '1px'}}><Link to="/">ย้อนกลับ</Link></span> */}
        {
          // query.get('state') == 1 ? <span className="previous"><Link to="/servicegroup">ย้อนกลับ</Link></span> : <span className="previous"><Link to="/">ย้อนกลับ</Link></span>
          <span className="previous">
            <Link to="/servicegroup">ย้อนกลับ</Link>
          </span>
        }
      </div>
      <CopyRight2 />
    </div>
  );
}

export default Service6;
