// import '../src/App.css';

// import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import Home from "./components/Home";

import Services from "./components/Services";
import Service1 from "./components/Service1";
import Service2 from "./components/Service2";
import Service3 from "./components/Service3";
import Service4 from "./components/Service4";
import Service5 from "./components/Service5";
import Service6 from "./components/Service6";

import Servicegroup from "./components/Servicegroup";
//import Webboard from './components/Webboard';

import Intro1 from "./components/Intro1";
import Intro2 from "./components/Intro2";
import Intro3 from "./components/Intro3";
import Intro4 from "./components/Intro4";

import Aboutus from "./components/Aboutus";
import User from "./components/User";

// import Chat2 from './components/Chat2';
//import ChatLog from './components/ChatLog';

// import Tutors from './components/Tutors';

import AcctaxIntro from "./components/AcctaxIntro";
import Accnew from "./components/Accnew";

import Proposal from "./components/Proposal";
import Login from "./components/Login";
import Mainpro from './components/Mainpro';
import Users from './components/Users';

function App() {
  // const reload = () => window.location.reload();
  library.add(fas, fab, far);
  return (
    <div>
      {/* <Switch>
					<Route exact path="/"><Home/></Route>
					<Route path="/servicegroup"><Servicegroup/></Route>
					<Route path="/services"><Services/></Route>
					<Route path="/service1"><Service1/></Route>
					<Route path="/service2"><Service2/></Route>
					<Route path="/service3"><Service3/></Route>
					<Route path="/service4"><Service4/></Route>
					<Route path="/service5"><Service5/></Route>
					<Route path="/service6"><Service6/></Route>					

					<Route path="/intro1"><Intro1/></Route>
					<Route path="/intro2"><Intro2/></Route>
					<Route path="/intro3"><Intro3/></Route>
					<Route path="/intro4"><Intro4/></Route>
					
					<Route path="/aboutus"><Aboutus/></Route>

					<Route path="/acctaxintro"><AcctaxIntro/></Route>

					<Route path="/accnew"><Accnew/></Route>
					
					<Route path="/user"><User/></Route>

					<Route path="/proposal"><Proposal/></Route>

					<Route path="/login"><Login/></Route>

				</Switch> */}

        <Routes>
			<Route exact path="/" element={<Home />} />
			
			<Route path="/servicegroup" element={<Servicegroup/>}/>
			<Route path="/services" element={<Services/>}/>
			<Route path="/service1" element={<Service1/>}/>
			<Route path="/service2" element={<Service2/>}/>
			<Route path="/service3" element={<Service3/>}/>
			<Route path="/service4" element={<Service4/>}/>
			<Route path="/service5" element={<Service5/>}/>
			<Route path="/service6" element={<Service6/>}/>				

			<Route path="/intro1" element={<Intro1/>}/>
			<Route path="/intro2" element={<Intro2/>}/>
			<Route path="/intro3" element={<Intro3/>}/>
			<Route path="/intro4" element={<Intro4/>}/>
			
			<Route path="/aboutus" element={<Aboutus/>}/>

			<Route path="/acctaxintro" element={<AcctaxIntro/>}/>

			<Route path="/accnew" element={<Accnew/>}/>
			
			<Route path="/user" element={<User/>}/>

			<Route path="/proposal" element={<Proposal/>}/>

			<Route path="/login" element={<Login/>}/>   

			<Route path="/mainpro" element={<Mainpro/>}/>

			<Route path="/users" element={<Users/>}/>
        </Routes>
    </div>
  );
}

export default App;