import React from "react";

import HeaderBar from "./HeaderBar";
// import Footer from './Footer';
import CopyRight2 from "./CopyRight2";

import { Link } from "react-router-dom";

function Servicegroup() {
  return (
    <div className="container-full">
      <HeaderBar />
      <div className="main--servicegroup">
        <div className="main--servicegroup--item">
          <Link to="/service1">
            <p>
              <img
                src="../images/service1.jpg"
                alt=""
                className="img-service-md"
              />
            </p>
            <p className="servicetext">รับทำบัญชี ปิดงบการเงิน</p>
          </Link>
        </div>

        <div className="main--servicegroup--item">
          <Link to="/service2">
            <p>
              <img
                src="../images/service2.jpg"
                alt=""
                className="img-service-md"
              />
            </p>
            <p className="servicetext">
              รับจดทะเบียนจัดตั้งบริษัท ห้างฯ และทะเบียนพาณิชย์
            </p>
          </Link>
        </div>

        <div className="main--servicegroup--item">
          <Link to="/service3">
            <p>
              <img
                src="../images/service3.jpg"
                alt=""
                className="img-service-md"
              />
            </p>
            <p className="servicetext">รับตรวจสอบบัญชี</p>
          </Link>
        </div>

        <div className="main--servicegroup--item">
          <Link to="/service4">
            <p>
              <img
                src="../images/service4.jpg"
                alt=""
                className="img-service-md"
              />
            </p>
            <p className="servicetext">ปรึกษาบัญชี และภาษี</p>
          </Link>
        </div>

        <div className="main--servicegroup--item">
          <Link to="/service5">
            <p>
              <img
                src="../images/service5.jpg"
                alt=""
                className="img-service-md"
              />
            </p>
            <p className="servicetext">รับบริการยื่นประกันสังคม</p>
          </Link>
        </div>

        <div className="main--servicegroup--item">
          <Link to="/service6">
            <p>
              <img src="../images/acctax.jpg" alt="" className="acctax-logo" />
            </p>
            <p className="servicetext">บริการเสริมพิเศษ</p>
          </Link>
        </div>
      </div>
      <CopyRight2 />
    </div>
  );
}

export default Servicegroup;
