import React,{useState, useEffect} from "react";
import Axios from 'axios';

function Mainpro() {
  const [userList, setUserList] = useState([]);

  const getUsers = ()=> {
    Axios.get(process.env.REACT_APP_API_URL + '/controllers/user/getUsers')
    .then((results) => {
      setUserList(results.data);
    })
  }

  useEffect(() => {
    getUsers();
  },[]);

  return (
    <div>
      <div class="sidebar">
        <a class="active" href="#home">
          Home
        </a>
        <a href="#news"><span class="bi bi-youtube icon-red icon-md"></span>News</a>
        <a href="#contact">Contact</a>
        <a href="#about">About</a>
      </div>

      <div class="content" id="home">
        <h2>Responsive Sidebar Example</h2>
        <p>
          This example use media queries to transform the sidebar to a top
          navigation bar when the screen size is 700px or less.
        </p>
        <p>
          We have also added a media query for screens that are 400px or less,
          which will vertically stack and center the navigation links.
        </p>
        <h3>Resize the browser window to see the effect.</h3>
      </div>

      <div class="content" id="news">
        <h2>Responsive Sidebar News</h2>
        <p>
          This example use media queries to transform the sidebar to a top
          navigation bar when the screen size is 700px or less.
        </p>
        <p>
          We have also added a media query for screens that are 400px or less,
          which will vertically stack and center the navigation links.
        </p>
        <h3>Resize the browser window to see the effect.</h3>
      </div>     

      {userList.map((val, key) => {
        return (
          <div className="employee card">
            <div className="card-body text-left">
              <p className="card-text">Name: {val.username}</p>   
              <p>Password : {val.password}</p>
            </div>
          </div>
        );
      }   
    )}
    </div>    
  ); 
}

export default Mainpro;
