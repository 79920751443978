import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderBar from "./HeaderBar";
import CopyRight2 from "./CopyRight2";

const Aboutus = (props) => {
  return (
    <div className="container-full">
      <HeaderBar />      

      <div className="content__default--aboutus">
        <div className="bg-transparent">
          {/* <img className="logo--small" src="../images/logo.jpg"></img> */}
        </div>

        <div>
          <span className="aboutus-header text-primary">
            บริษัท แอ็คเค้าท์ติ้ง ซิสเต็ม จำกัด
          </span>{" "}
          <br />
          เลขทะเบียนนิติบุคคล&nbsp;&nbsp;
          <span className="fw-bold text-primary">0105545090036</span> <br />
          เลขประจำตัวผู้เสียภาษีอากร&nbsp;&nbsp;
          <span className="fw-bold text-primary">0105545090036</span> <br />
          ทุนจดทะเบียน <span className="fw-bold text-primary">1,000,000</span> บาท
        </div>

        <hr />        

        <div className="header--step">
          <img src="./images/sv-03.jpg" className="img-fluid"/>
        </div>

        <br />
        <br />        
        
        <div className="indent">
          บริษัทของเราได้รับความเชื่อถือ
          และไว้วางใจจากลูกค้ามากว่า <span  className="fw-bold text-primary">20</span>{" "}
          ปี บริหารงานโดยผู้มีประสบการณ์ และผู้เชี่ยวชาญด้านบัญชี และภาษีโดยตรง
          เราจึงรู้จริง รักษาผลประโยชน์ของลูกค้าได้
          และยังมุ่งเน้นการบริการที่มีคุณภาพ ให้แก่ธุรกิจทุกขนาดในหลากหลายประเภท
          โดยให้บริการด้านบัญชีและภาษีอากรครบวงจร รวมถึงเป็นที่ปรึกษาปัญหาต่างๆ
          และยังช่วยท่านแก้ปัญหาจากองค์กรของภาครัฐ
        </div>
        <br />
        <div>
          <span className="aboutus-header">ที่อยู่</span> <br />
          313 ซอยลาดพร้าว87 (จันทราสุข) ถนนลาดพร้าว <br />
          แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง กทม.10310 <br />
        </div>
        <br />
        <div>
          <span className="aboutus-header">ที่ติดต่อ</span> <br />
          โทรศัพท์ 02-9313100-2, 02-9313105-8 <br />
          โทรสาร 02-9313103-4
          <br />
          email: accsystemthai@gmail.com
          <br />
          line: @accscan
          
        </div>
        
        <span className="previous">
          <Link to="/">กลับหน้าหลัก</Link>
          {/* <Link to="/login">Login</Link> */}
        </span>
      </div>
      <CopyRight2 />
    </div>
  );
};

export default Aboutus;