import React from "react";

import HeaderBar from "./HeaderBar";
import CopyRight2 from "./CopyRight2";

import { Link } from "react-router-dom";

function Intro1() {
  return (
    <div className="container-full" id="top">
      <HeaderBar />
      <div className="main--intro">
        <div className="d-flex flex-row">
          <div className="text-primary d-flex align-items-center">
            <i class="bi bi-stack icon-intro-lg"></i>
          </div>
          <div className="p-5 text-primary fw-bold intro-item-header">ประสบการณ์บัญชี และภาษี</div>
        </div>

        <div>
          <hr />
          <p className="indent">
            ประสบการณ์ การให้บริการด้านวิชาชีพ มีความเชี่ยวชาญในการแก้ปัญหา
            และให้คำแนะนำ วิธีปฎิบัติที่ถูกต้อง และประสบความสำเร็จ
          </p>
        </div>

        <div className="header--step">
          <img src="./images/sv-03.jpg" className="img-fluid" />
        </div>

        <span className="previous-intro">
          <Link to="/">ย้อนกลับ</Link>
        </span>
      </div>
      <CopyRight2 />
    </div>
  );
}

export default Intro1;
