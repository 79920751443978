import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import {useNavigate} from 'react-router-dom';
// import { useHistory } from "react-router-dom";

function Login() {
  let [count, setCount] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  // const history = useHistory();

  const checkUser = (e) => {
    e.preventDefault();
    
    Axios      
      // .get(process.env.REACT_APP_API_URL + `/api/user/checkuser/${username}`)
      .get(process.env.REACT_APP_API_URL + '/controllers/user/checkuser',{username: username})      
      .then((res) =>
          //navigate('/Users')         
          //alert(res.data.length)
          {
            if(!res.data)
            {
                alert('ไม่มีข้อมูล');
            }
            else
            {
                navigate('/Mainpro');
            }
          }
    )
      .catch((err) => alert(err));
  };

  const addUser = (e) => {
    e.preventDefault();

    Axios.post(process.env.REACT_APP_API_URL + '/controllers/user/adduser', {username: username, password: password})
    .then((response) => navigate('/Mainpro'))
    .catch((err) => alert(err));
  }  

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div className="login mt-3">
      <h2>Login Form</h2>
      <form className="was-validated1">
        <div className="mt-3 mb-3">
          <label htmlFor="username" className="form-label">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="username"
            className="form-control"
            // required
            onChange={(e) => setUsername(e.target.value)}
          />          
          {/* <div className="invalid-feedback">กรุณาพิมพ์ username</div> */}
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="password"
            className="form-control"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="mb-3 form-check">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              name="rememberme"
            />
            Remember
          </label>
        </div>

        <button
          type="submit"
          className="btn btn-primary form-control"
          onClick={checkUser}
        >
          OK
        </button>
      </form>
    </div>
  );
}

export default Login;