import React from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

function HeaderCarousel() {
  return (
    <div className="header--step">
      <header>
        <div className="header--step--text container-fluid">
          <p className="text-center text-primary proposal-header">ข้อเสนอ และการบริการ<span>&nbsp;&nbsp;<Link to="/proposal">(ดูรายละเอียด)</Link></span></p>
          <div id="demo" className="carousel slide container-fluid" data-ride="carousel">
            <ul className="carousel-indicators">
              <li data-target="#demo" data-slide-to="0" className="active"></li>
              <li data-target="#demo" data-slide-to="1"></li>
              <li data-target="#demo" data-slide-to="2"></li>
              <li data-target="#demo" data-slide-to="3"></li>
              <li data-target="#demo" data-slide-to="4"></li>
              <li data-target="#demo" data-slide-to="5"></li>
              <li data-target="#demo" data-slide-to="6"></li>
              <li data-target="#demo" data-slide-to="7"></li>
              <li data-target="#demo" data-slide-to="8"></li>
              <li data-target="#demo" data-slide-to="9"></li>
              <li data-target="#demo" data-slide-to="10"></li>
              <li data-target="#demo" data-slide-to="11"></li>
              <li data-target="#demo" data-slide-to="12"></li>
              <li data-target="#demo" data-slide-to="13"></li>
              <li data-target="#demo" data-slide-to="14"></li>
              <li data-target="#demo" data-slide-to="15"></li>
              <li data-target="#demo" data-slide-to="16"></li>
              <li data-target="#demo" data-slide-to="17"></li>
              <li data-target="#demo" data-slide-to="18"></li>
              <li data-target="#demo" data-slide-to="19"></li>
              <li data-target="#demo" data-slide-to="20"></li>
            </ul>

            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="d-block img-fluid" src="./images/sv-01.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-02.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-03.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-04.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-05.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-06.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-07.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-08.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-09.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-10.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-11.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-12.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-13.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-14.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-15.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-16.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block" src="./images/sv-17.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-18.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-19.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-20.jpg" />
              </div>
              <div className="carousel-item">
                <img className="d-block img-fluid" src="./images/sv-21.jpg" />
              </div>
            </div>

            <a className="carousel-control-prev" href="#demo" data-slide="prev">
              <span className="carousel-control-prev-icon text-bg-warning"></span>
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <span className="carousel-control-next-icon text-bg-warning"></span>
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default HeaderCarousel;
