import React from "react";
//import '../App.css';

//bootstrap icon
import "bootstrap-icons/font/bootstrap-icons.css";

const CopyRight = () => {
  return (
    <div>
      <footer>
        <p className="copyright">
          Copyright&nbsp;<i class="bi bi-c-circle icon-white"></i>
          &nbsp;2021&nbsp;All Rights Reserved.&nbsp;&nbsp;
            <i class="bi bi-people-fill text-warning"></i>&nbsp;จำนวนเข้าชม
          &nbsp;&nbsp;
            <a
              title="Web Analytics"
              href="https://statcounter.com/"
              target="_blank"
            >
              <img
                class="statcounter"
                src="https://c.statcounter.com/12775956/0/aa0a2e7c/0/"
                alt="Web Analytics" 
              />
            </a>
        </p>
      </footer>
    </div>
  );
};

export default CopyRight;
