import {
  COUNTER_FETCH,
  COUNTERS_FETCH,
  COUNTERS_CREATE,
  COUNTERS_DELETE,
  COUNTERS_UPDATE,
  COUNTERS_GETDATA,
  COUNTER
} from "../actions/types";

export default function (state = 0, action) {
  switch (action.type) {
    case COUNTER_FETCH:
    case COUNTERS_FETCH:
      return action.payload;
    case COUNTERS_DELETE:
      return { saved: true, msg: "Save" };
    case COUNTERS_GETDATA:
        return action.payload;
    case COUNTER:
      return action.payload;
    default:
      return state;
  }
}
