import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

/*import { IconContext } from "react-icons";
import { FaCreativeCommonsShare,FaMoneyCheckAlt,FaRegShareSquare,FaCommentDollar } from "react-icons/fa";*/

import { Link } from "react-router-dom";

/*import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";*/
import { parse } from "ipaddr.js";

// library.add(fas, fab, far);

const Introserv = () => {
  return (
    <div className="intro-container">
      <div className="intro-item">
        <div>
          <u>ประสบการณ์บัญชี และภาษี</u>
        </div>
        <div>
          <Link to="/intro1">
            <i class="bi bi-stack icon-lg"></i>
          </Link>
        </div>
      </div>

      <div className="intro-item">
        <div>
          <u>ผู้เชี่ยวชาญบัญชี และภาษี</u>
        </div>
        <div>
          <Link to="/intro2">
            <i class="bi bi-person-lines-fill icon-lg"></i>
          </Link>
        </div>
      </div>

      <div className="intro-item">
        <div>
          <u>รวดเร็ว และมีประสิทธิภาพ</u>
        </div>
        <div>
          <Link to="/intro3">
            <i class="bi bi-clock-fill icon-lg"></i>
          </Link>
        </div>
      </div>

      <div className="intro-item">
        <div>
          <u>ค่าบริการที่เหมาะสม คุ้มค่า</u>
        </div>
        <div>
          <Link to="/intro4">
            <i class="bi bi-currency-exchange icon-lg"></i>
          </Link>
        </div>
      </div>


    </div>
  );
};

export default Introserv;
