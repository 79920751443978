import React from "react";
import HeaderBar from "./HeaderBar";
import CopyRight2 from "./CopyRight2";

function AcctaxIntro() {
  return (
    <div className="container">
      <HeaderBar />
      <div className="position-relative" style={{ top: "150px" }}>
        <div>โปรแกรมบัญชี AccTax</div>
        <div className="d-flex p-3">
          <div className="bg-primary p-2 flex-fill">data1</div>
          <div className="bg-warning p-2 flex-fill">data2</div>
          <div className="bg-danger p-2 flex-fill">data3</div>
        </div>
      </div>


      {/* <CopyRight2/> */}
    </div>
  );
}
export default AcctaxIntro;
