import React from "react";
import { IconContext } from "react-icons";
import { FaMoneyCheckAlt } from "react-icons/fa";

import HeaderBar from "./HeaderBar";
import CopyRight2 from "./CopyRight2";

import { Link } from "react-router-dom";

function Intro2() {
  return (
    <div className="container-full">
      <HeaderBar />
      <div className="main--intro">
        <div className="d-flex flex-row">
          <div className="text-primary d-flex align-items-center">
            <i class="bi bi-person-lines-fill icon-intro-lg"></i>
          </div>
          <div className="p-5 text-primary fw-bold intro-item-header">ผู้เชี่ยวชาญบัญชี และภาษี</div>
        </div>

        <div>
          <hr />
          <p className="indent lh-lg">
            ให้คำปรึกษากับลูกค้าที่มีปัญหาด้านภาษีอากรให้เรื่องยากกลายเป็นเรื่องง่ายๆ
            เพื่อรักษาผลประโยชน์ของลูกค้าเป็นสำคัญ
            และยังมุ่งเน้นการบริการที่มีคุณภาพให้แก่ธุรกิจทุกขนาด ในหลากหลายทุกประเภทกิจการ
          </p>
        </div>

        <div className="header--step">
          <img src="./images/sv-13.jpg" className="img-fluid" />
        </div>

        <span className="previous-intro">
          <Link to="/">ย้อนกลับ</Link>
        </span>
      </div>

      <CopyRight2 />
    </div>
  );
}

export default Intro2;
