import React,{useEffect} from "react";
import { Link } from "react-router-dom";

function Service() {
  // useEffect(() => {
  //     const Moving1 = document.getElementById("moving1");
  //     const Moving2 = document.getElementById("moving2");
  //     const Moving3 = document.getElementById("moving3");
  //     const Moving4 = document.getElementById("moving4");
  //     const Moving5 = document.getElementById("moving5");
  //     const Moving6 = document.getElementById("moving6");

  //     document.onmousemove = (ev) => {
  //       const positionX = (window.innerWidth / -200 - ev.x) / -100;
  //       const positionY = -ev.y / 90;

  //       Moving1.style.transform = `translate(${positionX}px, ${positionY}px)`;
  //       Moving2.style.transform = `translate(${positionX}px, ${positionY}px)`;
  //       Moving3.style.transform = `translate(${positionX}px, ${positionY}px)`;
  //       Moving4.style.transform = `translate(${positionX}px, ${positionY}px)`;
  //       Moving5.style.transform = `translate(${positionX}px, ${positionY}px)`;
  //       Moving6.style.transform = `translate(${positionX}px, ${positionY}px)`;

  //     };
  //   }
  // );

  return (
    <div className="grid-container mt-5">
      <div className="w-100 bg-white">
        <p className="content__header--service"><i class="bi bi-stack icon-sm"></i>&nbsp;รับทำบัญชี และยื่นแบบภาษี</p>  
        <div className="ps-3 pe-3 pt-0 pb-4 lh-lg">
        {/* <div>
          <img id="moving1"
            src="../images/service4.jpg"
            alt=""
            className="content--service--image h-auto img-fluid"
          />
        </div> */}

        <div className="content__header--service--item">
          <ul>
            <li>
              ให้คำแนะนำการจัดทำเอกสาร
              และการจัดเก็บเอกสารขั้นพื้นฐานในการบันทึกบัญชี
              รวมถึงเอกสารด้านบัญชีอื่นๆ ที่เกี่ยวข้องกับธุรกิจ
            </li>
            <li>วิเคราะห์ข้อมูลในการติดตามผลการดำเนินงาน</li>
            <li>
              รายงานงบการเงินที่ถูกต้อง รวดเร็ว
              เพื่อให้การบริหารผลการดำเนินงานเป็นไปตามเป้าหมายที่กำหนด
              อย่างมีประสิทธิภาพ
            </li>
            <li>
              บริการจัดการด้านภาษีอากร การยื่นภาษีต่างๆ ผ่านระบบออนไลน์
              เพื่อความถูกต้องและตรงต่อเวลา ลดความเสี่ยงในข้อผิดพลาดเรื่องกฎหมาย
              และกฏระเบียบต่างๆ
            </li>
            <li>
              จัดทำสมุดรายวันที่เกี่ยวข้อง
              <ul>
                <li>สมุดรายวันทั่วไป</li>
                <li>สมุดรายวันซื้อ</li>
                <li>สมุดรายวันขาย</li>
                <li>สมุดรายวันรับเงิน</li>
                <li>สมุดรายวันจ่ายเงิน</li>
              </ul>
            </li>
          </ul>
          <Link to="/service1">
            <span className="link-button">อ่านต่อ...</span>
          </Link>
        </div>
      </div>      
      </div>
      

      <div className="w-100 bg-white">
        <p className="content__header--service"><i class="bi bi-stack icon-sm"></i>&nbsp;
          รับจดทะเบียนจัดตั้งบริษัท ห้างฯ และทะเบียนพาณิชย์
        </p>
        <div>
        <div className="ps-3 pe-3 pt-0 lh-lg pb-4 content__header--service--item">
          <ul>
            <li>จดทะเบียนจัดตั้งบริษัท ห้างหุ้นส่วนจำกัด จดทะเบียนพาณิชย์</li>
            <li>
              ให้คำปรึกษาการจดทะเบียนบริษัท ห้างหุ้นส่วนจำกัด ทะเบียนพาณิชย์
            </li>
            <li>ขอเลขประจำตัวผู้เสียภาษีอากร และขึ้นทะเบียนประกันสังคม</li>
            <li>
              ดำเนินการคัดเอกสารทั้งจากกรมพัฒนาธุรกิจการค้า
              พร้อมจัดส่งให้ลูกค้าภายหลังจดทะเบียนเสร็จสิ้น
            </li>
            <li>
              ให้ความสะดวกไม่ต้องเดินทาง รับ-ส่ง เอกสารให้ถึงที่ทุกขั้นตอน
            </li>
            <li>รวดเร็ว ทันเวลา และมีคุณภาพ</li>
            <li>บริการเสริมพิเศษ</li>
          </ul>
          <Link to="/service2">
            <span className="link-button">อ่านต่อ...</span>
          </Link>
        </div>

        {/* <div>
          <img id="moving2"
            src="../images/steve-johnson.jpg"
            alt=""
            className="content--service--image h-auto img-fluid"
          />
        </div> */}
      </div>
      </div>      

      <div className="w-100 bg-white">
        <p className="content__header--service"><i class="bi bi-stack icon-sm"></i>&nbsp;รับตรวจสอบบัญชี</p>
        <div className="ps-3 pe-3 pt-0 lh-lg pb-4">
        {/* <div>
          <img id="moving3"
            src="../images/service2.jpg"
            alt=""
            className="content--service--image h-auto img-fluid"
          />
        </div> */}

        <div className="content__header--service--item">
          <p className="fw-bold text-primary">รับตรวจสอบบัญชี โดยผู้สอบบัญชีรับอนุญาต (CPA) พร้อมทีมงานที่มีความรู้
          ความสามารถ เน้นถึงคุณภาพของงานตรวจสอบ และความตรงต่อเวลา</p>
          <ul>
            <li>รับตรวจสอบ และแสดงความเห็นต่องบการเงินประจำปี</li>
            <li>ตรวจสอบบัญชีตามมาตราฐานการสอบบัญชีที่รับรองทั่วไป</li>
            <li>ทดสอบหลักฐานประกอบรายการ</li>
            <li>ประเมินความเหมาะสมของหลักการบัญชี และประเมินการทางบัญชี</li>
            <li>
              ประเมินความเหมาะสมของการเปิดเผยข้อมูล และการนำเสนองบการเงินโดยรวม
            </li>
            <li>
              ตรวจสอบ และรับรองบัญชีห้างหุ้นส่วน จดทะเบียนโดยผู้สอบบัญชีภาษีอากร
              โดยปฎิบัติงานตรวจสอบตามหลักการ วิธีการ
              และเงื่อนไขที่อธิบดีกรมสรรพากรกำหนด (TA)
            </li>
          </ul>
          <Link to="/service3">
            <span className="link-button">อ่านต่อ...</span>
          </Link>
        </div>
      </div>
      </div>      

      <div className="w-100 bg-white">
        <p className="content__header--service"><i class="bi bi-stack icon-sm"></i>&nbsp;ปรึกษาบัญชี และภาษี</p>
        <div className="ps-3 pe-3 pt-0 lh-lg pb-4">
        <div className="content__header--service--item">
          <ul>
            <li>
              ให้คำปรึกษาการจัดทำบัญชี ตามหลักการควบคุมภายในที่ดี
              เพื่อการบริหารงานที่มีประสิทธิภาพ
            </li>
            <li>
              ให้คำแนะนำวิธีการ เพื่อผลประโยชน์ด้านภาษีอากรที่ถูกต้องตามกฎหมาย
              และประหยัด
            </li>
            <li>
              ให้คำแนะนำระบบบัญชี ผังทางเดินเอกสาร
              รวมถึงเอกสารเบื้องต้นที่จำเป็นในการบันทึกบัญชี
            </li>
            <li>
              วางแผนการเสียภาษีเงินได้ ภาษีมูลค่าเพิ่ม ภาษีโรงเรือน ให้ประหยัด
              และถูกต้อง
            </li>
            <li>
              เป็นตัวแทนในการพบเจ้าหน้าที่กรมสรรพากร กรมทะเบียนการค้า
              กระทรวงแรงงานในกรณีที่มีหนังสือเชิญพบ
            </li>
          </ul>

          <Link to="/service4">
            <span className="link-button">อ่านต่อ...</span>
          </Link>
        </div>

        {/* <div>
          <img id="moving4"
            src="../images/bruno-emmanuelle-small.jpg"
            alt=""
            className="content--service--image h-auto img-fluid"
          />
        </div> */}
      </div>
      </div>      

      <div className="w-100 bg-white">
        <p className="content__header--service"><i class="bi bi-stack icon-sm"></i>&nbsp;รับบริการยื่นประกันสังคม</p>
        <div className="p-3 lh-lg ps-3 pe-3 pt-0 lh-lg pb-4">
        {/* <div>
          <img id="moving5"
            src="../images/service3.jpg"
            alt=""
            className="content--service--image h-auto img-fluid"
          />
        </div> */}

        <div className="content__header--service--item">
          <ul>
            <li>บริการจัดทำแจ้งพนักงานเข้า และออก ประกันสังคม</li>
            <li>
              บริการจัดทำ และยื่นแบบ การนำส่งเงินสมทบประกันสังคมรายเดือน
              และกองทุนเงินทดแทนที่กระทรวงแรงงาน และสวัสดิการสังคม
            </li>
            <li>ให้คำปรึกษาด้านประกันสังคมที่เกี่ยวข้องกับกิจการ</li>
            <li>
              บริการจัดทำแบบคำนวณค่าจ้าง เพื่อประกอกการรายงานค่าจ้างตามแบบ กท.20
              ประจำปี และยื่นต่อสำนักงาน
            </li>
            <li>ให้บริการแจ้งแก้ไข และเปลี่ยนแปลงการเข้า-ออกของพนักงาน</li>
          </ul>
          <Link to="/service5">
            <span className="link-button">อ่านต่อ...</span>
          </Link>
        </div>
      </div>
      </div>
      

      <div className="w-100 bg-white">
        <p className="content__header--service"><i class="bi bi-stack icon-sm"></i>&nbsp;บริการเสริมพิเศษ</p>
        <div className="ps-3 pe-3 pt-0 lh-lg pb-4">
        <div className="content__header--service--item">
          <ol type="1">
            <li>ฟรีโปรแกรมบัญชี AccTax</li>
            <li>บริการขอ ภ.อ.01 ยื่นแบบชำระภาษีทาง Internet</li>
            <li>จดภาษีมูลค่าเพิ่มทางอินเตอร์เน็ต</li>
            <li>ขอรหัสยื่นงบในระบบ e-filing</li>
            <li>จัดทำรายงานการประชุม สำหรับเปิดบัญชีธนาคาร</li>
            <li>คู่มือข้อควรปฎิบัติเบื้องต้น ภายหลังจากการจดทะเบียนแล้ว</li>
            <li>
              รับสิทธิพิเศษ จะได้รับส่วนลดค่าทำบัญชีฟรี 2 เดือน
              เมื่อรับบริการจดทะเบียนจัดตั้งบริษัท
            </li>
          </ol>
          <Link to="/service6">
            <span className="link-button">อ่านต่อ...</span>
          </Link>
          <br/>
          {/* <Link to="/acctaxintro">
            <span className="link-button">โปรแกรม AccTax</span>
          </Link> */}
        </div>

        {/* <div>
          <img id="moving6"
            src="../images/acctax.jpg"
            alt=""
            className="content--service--image content__header--service--acctax h-auto img-fluid"
          />
        </div> */}
      </div>
      </div>
      
    </div>
  );
}

export default Service;
