import React from "react";
// import "../App.css";

//  import { library } from "@fortawesome/fontawesome-svg-core";
//  import { fas } from "@fortawesome/free-solid-svg-icons";
//  import { fab } from "@fortawesome/free-brands-svg-icons";
//  import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// library.add(fas, fab, far);

const Intro = () => {
  return (
    <div className="intro-header">
      <div className="intro">
        {/* <div>
          <p className="intro--text">เราพร้อมยินดีให้คำปรึกษา</p>
          <p className="intro--text">โดยทีมงานที่มีประสบการณ์</p>
          <p>
            <span className="intro--text">รวดเร็ว ทันเวลา </span>
            <span className="text-warning intro--text">ปรึกษาฟรี</span>
          </p>
        </div> */}

        {/* <div className="intro--right">
          <div className="intro--tel">
            <div>              
              <i class="bi bi-telephone-outbound icon-white icon-md"></i>
            </div>

            <div className="intro--left text-white">
              <div>ติดต่อเรา</div>
              <div>02-9313100-2</div>
            </div>
          </div>

          <div className="intro--tel intro--line">
            <div>
              <a href="http://line.me/ti/p/~accscan">                
                <i class="bi bi-line icon-white icon-md"></i>
              </a>
            </div>

            <div className="intro--left text-white">
              <div>Add LINE</div>
              <div>@accscan</div>
            </div>
          </div>
        </div> */}

        {/* <div>เราพร้อมยินดีให้คำปรึกษา</div>
        <div>โดยทีมงานที่มีประสบการณ์</div>
        <div>รวดเร็ว ทันเวลา ปรึกษาฟรี</div>

        <div>
        <i class="bi bi-telephone-outbound icon-orange icon-md"></i>
          &nbsp;
          02-9313100-2
        </div>

        <div>
          <a href="http://line.me/ti/p/~accscan">                
                <i class="bi bi-line icon-green icon-md"></i>
              </a>
          &nbsp;
          @accscan
        </div> */}
      </div>
    </div>
  );
};

export default Intro;
