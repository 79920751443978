import React from "react";
//import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

/*const containerStyle = {
  width: "600px",
  height: "450px",
};

const center = {
  lat: 13.792046, // ใส่ค่า latitude ของตำแหน่งบริษัทคุณ
  lng: 100.617899, // ใส่ค่า longitude ของตำแหน่งบริษัทคุณ
};*/

function Mapaccount() {
  return (
    <div className="mapaccount">
      {/* <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
          <Marker position={center} />
        </GoogleMap>
      </LoadScript> */}

      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.1738377681343!2d100.61642403771855!3d13.79204713851552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29dcddaacab71%3A0x470f033cc21778ae!2sPekko%E2%80%99s%20Office!5e0!3m2!1sen!2sth!4v1728615975442!5m2!1sen!2sth"
        //width="800"
        //height="450"
        //style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>*/}

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d814.5724360105174!2d100.61660863391401!3d13.792050628360162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d001fc66737%3A0x4c558c39cb3515fb!2sAccounting%20System%20Co.%2CLtd.!5e0!3m2!1sen!2sth!4v1728619979905!5m2!1sen!2sth"
        //width="600"
        //height="450"
        //style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Mapaccount;
