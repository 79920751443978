import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';

import "bootstrap/dist/css/bootstrap.min.css";

import {BrowserRouter} from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import {legacy_createStore as createStore, applyMiddleware } from "redux";

import reduxThunk from 'redux-thunk';
import rootReducer from '../src/reducers';

const store = createStore(rootReducer,{}, applyMiddleware(reduxThunk));

ReactDOM.render( 
	<React.StrictMode>
		<BrowserRouter>
		<Provider store={store}>
    	<App />
		</Provider>
		</BrowserRouter>
  </React.StrictMode>,	 
  document.getElementById('root')
);

reportWebVitals();
