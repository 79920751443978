import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

//use bootstrap icon
import "bootstrap-icons/font/bootstrap-icons.css";

function HeaderBar() {
  const [count, setCount] = useState(0);
  const [ip, setIp] = useState(0);

  /*const fetchData = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/counter/count")
      .then((res) => {
				setCount(res.data);
      })
      .catch((err) => setCount(-1));
  };	

  useEffect(() => {
		fetchData();		
		checkIp();
	}, [count]);

	const addData = () => {			
		axios.post(process.env.REACT_APP_API_URL + '/api/counter/add')
		.then((resp) => console.log('response: ',resp))
		.catch((err) => console.log(err));		
	}

	const checkIp = () => {
		axios.get(process.env.REACT_APP_API_URL + '/api/counter/ip')
		.then((res) => {
			setIp(res.data);				
		})
		.catch(err => console.log(err));
	}*/

  return (
    <div className="header__menu">
      <div className="logo-com">
        <img src="../images/logo.jpg" className="img-fluid w-100 h-100"></img>
      </div>

      <div className="header__menu--right">
        <div className="header__menu--right--default">
          <ul className="nav justify-content-end align-items-center">
            <li className="nav-item nav-link">
              <i class="bi bi-cloud-arrow-down-fill icon-blue h-auto icon-sm"></i>
              <Link to="/accnew">
                <span className="header__menu--right--top text-primary">
                  &nbsp;Download AccTax ฟรี
                </span>
              </Link>
            </li>
            <li className="nav-item nav-link">
              <i class="bi bi-filetype-xlsx icon-dodgerblue h-auto icon-sm"></i>
              <Link to="/files/AccFiles 1.1.rar" target="_blank" download>
                <span className="header__menu--right--top text-primary">
                  &nbsp;Download AccFile ฟรี
                </span>
              </Link>
            </li>
            <li className="nav-item nav-link">
              <i class="bi bi-line icon-green h-auto icon-sm"></i>
              &nbsp;
              <a
                href="http://line.me/ti/p/@204cczdz"
                data-toggle="tooltip"
                data-placement="top"
                title="ถาม-ตอบ การใช้งานโปรแกรม"
              >
                <span className="header__menu--right--top text-primary">
                  @204cczdz
                </span>
              </a>
            </li>
            <li className="nav-item nav-link h-auto">
              <a
                href="https://www.youtube.com/@acctax-ac"
                data-toggle="tooltip"
                data-placement="top"
                title="คลัง Video"
              >
                <i class="bi bi-youtube icon-red icon-sm"></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="header__menu--right--default">
          <ul className="nav justify-content-end align-items-end p-0 m-0">
            <li className="nav-item nav-link align-items-end">
              <Link to="/" className="font--menu">
                <i className="bi-house-check-fill text-primary h-auto icon-sm"></i>
                &nbsp;หน้าหลัก
              </Link>
            </li>
            <li className="nav-item nav-link">
              <Link to="/servicegroup" className="font--menu">
                บริการ
              </Link>
            </li>
            <li className="nav-item nav-link">
              <Link to="/aboutus" className="font--menu">
                เกี่ยวกับเรา
              </Link>
            </li>
            <li className="nav-item nav-link">
              <i class="bi bi-people-fill text-success h-auto icon-sm"></i>
              <span className="font-weight-bold text-primary font--menu--count">
                &nbsp;จำนวนเข้าชม
              </span>
              &nbsp;
              <a
                title="จำนวนเข้าชม"
                href="https://statcounter.com/"
                target="_blank"
              >
                <img
                  class="statcounter"
                  src="https://c.statcounter.com/12775956/0/aa0a2e7c/0/"
                  alt="Web Analytics"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default HeaderBar;
